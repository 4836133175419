import ExternalLink from '@aurora/shared-client/components/common/ExternalLink/ExternalLink';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import localStyles from './KhorosLogo.module.css';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;

  /**
   * Class name(s) to apply to the component element.
   */
  svgClassName?: string;
}

/**
 * Display Khoros Logo as link to khoros.com
 *
 * @author Adam Ayres
 */
const KhorosLogo: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  svgClassName
}: Props) => {
  const cx = useClassNameMapper(localStyles);
  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.KHOROS_LOGO);
  const title = textLoading ? '...' : formatMessage('alt');

  return (
    <ExternalLink
      href="https://khoros.com"
      className={cx(className)}
      testId="KhorosLogo.Link"
      ariaLabel={title}
    >
      <Icon
        icon={Icons.PoweredByKhorosLogoIcon}
        size={IconSize.FROM_CSS}
        title={title}
        className={cx(svgClassName, 'lia-khoros-svg')}
        testId="KhorosLogo.Image"
      />
    </ExternalLink>
  );
};

export default KhorosLogo;
